import { useState, useEffect, useRef } from "react";
import img from "../../../assets/images/steyp-invoice.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import "../../../assets/css/invoice.css";

function TechDegreeWineer() {
    const [posterGenerated, setPosterGenerated] = useState(false);

    const [Name, setName] = useState("");
    const [sId, setsId] = useState("");
    const [points, setPoints] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");
    const [startMonth, setStartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const imageRef = useRef(null);

    const [response, setResponse] = useState("");

    useEffect(() => {
        // Function to calculate the ordinal suffix
        const getOrdinalSuffix = (day) => {
          if (day >= 11 && day <= 13) {
            return "th";
          } else {
            switch (day % 10) {
              case 1:
                return "st";
              case 2:
                return "nd";
              case 3:
                return "rd";
              default:
                return "th";
            }
          }
        };
    
        // Check if both startdate and enddate have values
        if (startdate && enddate) {
          const startSuffix = getOrdinalSuffix(parseInt(startdate));
          const endSuffix = getOrdinalSuffix(parseInt(enddate));
    
          // Example response message with date numbers and ordinal suffixes
          const responseMessage = `The winner of ${startdate}${startSuffix} ${startMonth} - ${enddate}${endSuffix} ${endMonth} is`;
    
          // Set the response message
          setResponse(responseMessage);
        } else {
          // Clear the response if either startdate or enddate is empty
          setResponse("");
        }
      }, [startdate, enddate, startMonth, endMonth]);

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/pdf", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 1,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    // console.log(serviceName,"select value");

    //---------------------------------------------------------------------------
    return (
        <>
            <App className="invoice">
                <Bottom>
                    <DetailsHead> Tech Degree Weekly Winner</DetailsHead>
                    <Form>
                        <StudentInput>
                            <InputName>Student Name</InputName>
                            <InputField
                                type="text"
                                placeholder="Name"
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </StudentInput>

                        <StudentInput>
                            <InputName>Student ID</InputName>
                            <InputField
                                type="number"
                                placeholder="Student ID"
                                value={sId}
                                onChange={(e) => setsId(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Points</InputName>
                            <InputField
                                // className="address"
                                type="number"
                                placeholder="Points"
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Student Image</InputName>
                            <InputField
                                // className="address"
                                type="file"
                                onChange={handleFileInputChange}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Start Date</InputName>
                            <InputField
                                type="number"
                                placeholder="Start date"
                                value={startdate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>End Date</InputName>
                            <InputField
                                type="number"
                                placeholder="End date"
                                value={enddate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Start Month</InputName>
                            <InputField
                                type="text"
                                placeholder="Start Month"
                                value={startMonth}
                                onChange={(e) => setStartMonth(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>End Month</InputName>
                            <InputField
                                type="text"
                                placeholder="End Month"
                                value={endMonth}
                                onChange={(e) => setEndMonth(e.target.value)}
                            />
                        </StudentInput>
                    </Form>
                    <Generate onClick={generatePoster}>Generate</Generate>
                </Bottom>
                <Poster id="poster">
                    <Header>
                        <MianHeading>TECH DEGREE</MianHeading>

                        <LogoDiv>
                            <img
                                src={
                                    require("../../../assets/images/weekly-winner/winner.png")
                                        .default
                                }
                                alt="image"
                            />
                        </LogoDiv>
                        <DayContiner>
                            <span>
                            {response}
                            </span>
                        </DayContiner>
                        <NameDiv>
                            <h4>{Name}</h4>
                        </NameDiv>
                        <StudentIdContiner>
                            <span>Student ID : #{sId}</span>
                        </StudentIdContiner>
                        <PointContienr>
                            <span>{points} Points</span>
                        </PointContienr>

                        <StudentImageContier>
                            <ImageContienr>
                                {selectedFile && (
                                    <img
                                        ref={imageRef}
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Selected image"
                                    />
                                )}
                            </ImageContienr>
                        </StudentImageContier>
                    </Header>
                </Poster>

                <RenderPoster id="render"></RenderPoster>
                {posterGenerated ? (
                    <Container
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "50px",
                        }}
                    >
                        <a id="download" download="weeklywinner.png">
                            <Download onClick={download}>Download</Download>
                        </a>
                    </Container>
                ) : null}
            </App>
        </>
    );
}

const App = styled.div`
    display: flex;
    flex-direction: column;
    align-items: "center";
`;
const Poster = styled.div`
    /* width: 61%; */
    margin: 0 auto;
    margin-bottom: 110px;
`;

const Header = styled.div`
 position: relative;
    /* position: relative; */
    /* margin: 20px 0px; */
    width: 100%;
    max-width: 875px;
    min-width: 875px;
    /* padding: 0px 40px; */
`;
const LogoDiv = styled.div`
    width: 100%;
    img {
        display: block;
        width: 100%;
        object-fit: contain;
    }
`;

const Bottom = styled.div`
    margin-top: 500px;
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;
    flex-direction: column;
    width: 60%;
    border: 1px solid #b5e2f0;
    background-color: #f5fbfd;
    border-radius: 20px;
    padding: 40px 30px;
`;

const DetailsHead = styled.span`
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
`;
const Form = styled.div`
    text-transform: capitalize;
    font-weight: 500;
`;
const StudentInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
`;
const InputName = styled.p``;

const InputField = styled.input`
    padding: 15px;
    width: 70%;
    color: #000 !important;
    font-size: 17px;
    background-color: #fff;
    border: 1px solid #b5e2f0;
    border-radius: 5px;
    -webkit-appearance: none;
    margin: 0;
    ::placeholder {
        color: #7a777d !important;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const Generate = styled.span`
    font-size: 18px;
    font-weight: 500;
    margin: 30px auto 10px;
    display: block;
    padding: 15px 60px;
    background: #9fdbed;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background: #b5e2f0;
        border: 1px solid #b5e2f0;
        transition-duration: 0.1s;
    }
`;
const Download = styled.span`
    padding: 20px 100px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "product_sansbold";
    background: #d1e8e0;
    color: #000;
`;
const RenderPoster = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 65px;
    margin-top: 40px;
`;
const Container = styled.div`
    margin-top: 10px;
`;
const DayContiner = styled.div`
      top: 165px;
    position: absolute;
    width: 132%;
    margin: 0 auto;
    text-align: center;
    span {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #000;
        small{
            text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #00af8c;
        }
    }

`;
const NameDiv = styled.div`
    top: 48%;
    position: absolute;
    width: 130%;
    margin: 0 auto;
    text-align: center;
    h4 {
    font-size: 29px;
    text-align: center;
    font-weight: 600;
    color: #fff;
    width: 36%;
    margin: 0 auto;
    text-align: center;
    }
`;
const StudentIdContiner = styled.div`
    top: 59%;
    position: absolute;
    width: 130%;
    margin: 0 auto;
    text-align: center;

    text-align: center;

    span {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color:  #d8be62;
    }
`;
const PointContienr = styled.div`
        top: 69.4%;
    left: 60%;
    position: absolute;

    span {
        text-align: center;
        font-weight: 530;
        font-size: 27px;
        color:  #fff;
    }
`;
const StudentImageContier = styled.div`
          position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-146%,-41%);
`;
const MianHeading = styled.h3`
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    top: 6%;
    /* left: 42.8%; */
    color: #fff;
    /* font-weight: 500; */
    font-family: "baloo_paaji_2semibold";
    font-size: 23px;
`;

const ImageContienr = styled.div`
          width: 210px;
    height: 187px;
    overflow: hidden;
    img {
        width: 100%;
        height: 109%;
        display: block;
        border-radius: 50%;
    }
`;
export default TechDegreeWineer;
